<template>
  <div class="ly-account-header">
    <div class="ly-account-header__content g-content">
      <p class="account-header-title">{{ route.meta?.layout?.header === 'my-profile' ? '我的帳戶' : '我的音樂' }}</p>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'PersonalHeader',
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
});
</script>
<style lang="scss" scoped>
.ly-account-header {
  padding-top: 60px; // 撐開 header
  height: 110px;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  background-image: url('~@/assets/profile/banner_profile.png');

  &__content {
    @include flex();
    height: 100%;
  }
}

.account-header-title {
  @include font-style($c-white, 24, bold, 1.2px, 33px);
}

@media screen and (min-width: $tablet) {
  .ly-account-header {
    padding-top: 80px;
    height: 220px;
  }

  .account-header-title {
    @include font-style($c-white, 45, bold, 2.25px, 60px);
  }
}
</style>
